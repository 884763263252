$(function () {
  $("[layer-open]").click(function () {
    var title = $(this).attr("layer-title");
    var target = $(this).attr("layer-open");
    layer.open({
      type: 1,
      title: title,
      shadeClose: true,
      shade: 0.8,
      area: ["630px", "85%"],
      content: $("" + target),
    });
  });
});

$(function () {
  $("#username,#password_txt,#affKey").focus(function () {
    $(this).addClass("input-txt");
  });
  $("#username,#password_txt,#affKey")
    .blur(function () {
      $(this).removeClass("input-txt");
      var txt_value = $(this).val(); // 得到当前文本框的值
      if (txt_value != "") {
        $(this).addClass("input-txt");
      }
    })
    .blur();
});

$(function () {
  $(".box").niceScroll({
    cursorcolor: "#CC0071",
    cursoropacitymax: 1,
    touchbehavior: false,
    cursorwidth: "5px",
    cursorborder: "0",
    cursorborderradius: "5px",
  });
});

// Promotion loader
$(function () {
  if (window.promo) {
    renderPromo(window.promo);
  } else {
    Object.defineProperty(window, "promo", {
      set: function (data) {
        renderPromo(data);
      },
    });
  }

  function registerPromo(promoId) {
    api.registerPromo(promoId);
  }

  function renderPromo(promoData) {
    if (Array.isArray(promoData)) {
      var promoWrapper = $("#promodiv");
      promoData.forEach(function (value, index) {
        var promoItem = $(`<div class="contentbox" data-type="${value.type}"></div>`);
        var promoId = value.promoId;
        var requestToJoin = value.requestToJoin;
        var firstDepositPromoJoinMsg = value.firstDepositPromoJoinMsg;

        promoItem.append(
          $(`
                  <div class="actimg tc">
                    ${value.imagePath ? `<img src="${value.imagePath}"/>` : `${value.title}`}
                  </div>
                  <div class="actcont">
                    <div class="commontitle">
                        ${value.content}
                        ${
                          requestToJoin
                            ? `
                                 <div
                                   class="actfooter"
                                   style="display: -webkit-box; display: -ms-flexbox; display: flex; -webkit-box-align: center; -ms-flex-align: center; align-items: center; -webkit-box-pack: center; -ms-flex-pack: center; justify-content: center; height: 50px;font-size: 14px"
                                 >
                                   ${
                                     firstDepositPromoJoinMsg
                                       ? `
                                         <div class="actfooter--info" style="color: #555;">
                                           ${firstDepositPromoJoinMsg}
                                         </div>
                                       `
                                       : `
                                         <button
                                           class="actfooter--register-promo"
                                           data-id="${promoId}"
                                           style="background-color: #ef3e11;cursor:pointer;color:#fff;outline: 0;border:0;height:35px;width:120px;border-radius:10px;font-size:16px;"
                                         >
                                           申请参与
                                         </button>
                                       `
                                   }
                                 </div>
                               `
                            : ``
                        }
                    </div>
                  </div>
              `)
        );

        promoWrapper.append(promoItem);
      });

      $(".promo-standalone .activitycont").html(promoWrapper);

      $(".actfooter--register-promo").click(function (e) {
        e.preventDefault();
        var promoId = $(this).data("id");
        registerPromo(promoId);
      });
    }

    $(".promo-standalone .activitytab .tab-item").click(function () {
      var index = $(this).index();
      var dataType = $(this).data("type");

      $(this).siblings().removeClass("active");
      $(this).addClass("active");

      if (dataType === -1) {
        $(".contentbox").slideDown();
      } else {
        $('.contentbox[data-type="' + dataType + '"]').slideDown();
        $('.contentbox:not([data-type="' + dataType + '"])').slideUp();
      }

      loadPromoAnimation();
    });

    $(document).on("click", ".contentbox .actimg", function (e) {
      e.preventDefault();
      $(this).siblings(".actcont").slideToggle();
      $(this).parents(".contentbox").siblings(".contentbox").children(".actcont").slideUp();
    });

    $(document).on("click", ".kf_t .with-desc", function (e) {
      e.preventDefault();
      $(this).find(".img-desc").fadeToggle();
    });
  }
});

// Banner carousel
if (Pace) {
  Pace.on("done", initBanner);
} else {
  $(window).load(initBanner);
}

function initBanner() {
  initSwiper();
}

function initSwiper() {
  const bannerList = window.banner;
  const indexContainer = $(".swiper-wrapper");
  const isLoggedIn = $(".before-login.hide").length !== 0;

  bannerList &&
    bannerList !== 0 &&
    bannerList.forEach(function (item) {
      const { imageUrl, redirectUrl, redirectGroup, redirectPage, bannerType } = item;

      if (imageUrl) {
        /**
         * Redirect Group Option
         * 0 - Promo
         * 1 - Join Us
         * 2 - Lottery
         * 3 - Product
         * 4 - Others inner page (Loyalty, Customer Service)
         * 5 - 自定义
         */
        const banner = $(`
      <div class="swiper-slide">
        <img ${redirectUrl ? `data-redirect=${redirectUrl}` : ""} class="img" src="${imageUrl}"/>
        <div class="img-bg" style="background-image: url(${imageUrl})"></div>
      </div>
    `);

        banner.click(() => {
          if (redirectGroup === 1) {
            layer.open({
              type: 1,
              title: "代理合作",
              shadeClose: true,
              shade: 0.8,
              area: ["630px", "85%"],
              content: $("#m1"),
            });
          } else if (redirectGroup === 4 && redirectUrl === "/faq") {
            layer.open({
              type: 1,
              title: "常见问题",
              shadeClose: true,
              shade: 0.8,
              area: ["630px", "85%"],
              content: $("#m2"),
            });
          } else {
            window.initBannerCarouselRedirection({
              redirectUrl,
              redirectGroup,
              redirectPage,
              isLoggedIn,
            });
          }
        });

        /**
         * index append 首页type banner, xycly promo.html no banner
         * 轮播图类型 bannerType
         * 0 - 首页
         * 1 - 优惠活动
         * 2 - 其他
         */
        if (bannerType === 0) {
          indexContainer.append(banner);
        }
      }
    });

  if (bannerList && bannerList.length) {
    new Swiper(".swiper-container", {
      speed: 400,
      spaceBetween: 10,
      autoplay: {
        delay: 5000,
      },
      loop: true,
    });
  }
}
